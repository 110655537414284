import styled from 'styled-components'
import { Media } from '@raylink-overseas/common/theme'

export const SceneWrappe = styled.div`
  min-width: 1400px;
  padding: 112px 0;
  background: linear-gradient(180deg, #f1f3ff 0%, #f9faff 100%);
  ${Media.homeLaptop`
    min-width: 1184px;
    padding: 88px 0;
  `}
  ${Media.laptop`
    min-width: 1088px;
    padding: 64px 0;
  `}
  ${Media.ipad`
    min-width: 896px;
    padding: 58px 0;
  `}
  ${Media.phone`
    min-width: auto;
    padding: 0.76rem 0;
  `}
`
