import styled from 'styled-components'
import { Media, color } from '@raylink-overseas/common/theme'
import { BigWrapper } from '@raylink-overseas/common/components'

export const UserReviewsWrap = styled(BigWrapper)`
  margin: 112px auto;
  .background {
    width: 1384px;
    height: 468px;
    background: #f6f9ff;
    border-radius: 32px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  ${Media.homeLaptop`
    width: 1184px;
    margin: 88px auto;
    .background {
      width: 1170px;
      height: 384px;
    }
  `}
  ${Media.laptop`
    width: 1088px;
    margin: 64px auto;
    .background {
      width: 1070px;
    }
  `}
  ${Media.ipad`
    width: 896px;
    margin: 58px auto;
    .background {
      width: 880px;
    }
  `}

  ${Media.phone`
    margin: 0.76rem auto;
    padding: 0 0.32rem;
    width: 100%;
    .background {
      width: 6.86rem;
      height: 9.16rem;
      right: initial;
      border-radius: 0.4rem;
    }
  `}
`

export const Title = styled.h2`
  margin-bottom: 56px;
  color: #1f2329;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  ${Media.homeLaptop`
    margin-bottom: 48px;
    font-size: 34px;
  `}
  ${Media.laptop`
    margin-bottom: 40px;
    font-size: 32px;
  `}

  ${Media.phone`
    font-size: 0.48rem;
  `}
`

export const ReviewCard = styled.div<{
  background: string
  ipadBackground: string
  background1280: string
  mobilBackground: string
}>`
  width: 1400px;
  height: 468px;
  background: url(${({ background }) => background}) no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  ${Media.homeLaptop`
    width: 1184px;
    height: 384px;
  `}
  ${Media.laptop<{ background1280: string }>`
    width: 1088px;
    background: url(${({ background1280 }) => background1280}) no-repeat;
  `}
  ${Media.ipad<{ ipadBackground: string }>`
    width: 896px;
    border-radius: 26px;
    background: url(${({ ipadBackground }) => ipadBackground}) no-repeat;
  `}

  ${Media.phone<{ mobilBackground: string }>`
    width: 100%;
    height: 9.16rem;
    border-radius: 0.32rem;
    flex-direction: column;
    background: url(${({ mobilBackground }) => mobilBackground}) no-repeat;
    background-size: cover;
    .user-box-outer {
      display: flex;
    }
  `}
`

export const UserBox = styled.div`
  padding: 32px 0 32px 56px;
  width: 263px;
  height: 156px;
  cursor: pointer;
  .user {
    display: flex;
    align-items: center;
    gap: 16px;
    img {
      width: 64px;
    }
    .name {
      margin-bottom: 4px;
      color: #1f2329;
      font-size: 20px;
      font-weight: 500;
    }
    .occupation {
      color: ${color.textDark};
      font-size: 16px;
      font-weight: 400;
    }
  }
  .star {
    margin-top: 10px;
    width: 106px;
  }
  ${Media.homeLaptop`
    padding: 25px 0 15px 47px;
    width: 230px;
    height: 128px;
    .user {
      img {
        width: 56px;
      }
      .name {
        font-size: 18px;
      }
      .occupation {
        font-size: 14px;
      }
    }
    .star {
      margin-top: 6px;
    }
  `}
  ${Media.ipad`
    padding: 22px 0 20px 48px;
    width: 236px;
    height: 132px;
  `}

  ${Media.phone`
    flex: 1;
    padding: 0.54rem 0 0.34rem;
    width: 2.68rem;
    height: 2.92rem;
    text-align: center;
    .user {
      flex-direction: column;
      gap: 0.08rem;
      img {
        width: 0.96rem;
      }
      .name {
        margin-bottom: 0;
        font-size: 0.28rem;
      }
      .occupation {
        font-size: 0.24rem;
      }
    }
    .star {
      margin-top: 0.12rem;
      width: 1.08rem;
    }
  `}
`

export const ReviewConWrap = styled.div`
  flex: 1;
  padding: 45px 46px;
  ${Media.homeLaptop`
    padding: 32px;
  `}

  ${Media.phone`
    padding: 0.26rem 0.24rem;
  `}
`

export const ReviewCon = styled.div`
  width: 100%;
  height: 100%;
  background: #fff;
  box-shadow: 0px 14px 25px 0px rgba(224, 241, 255, 0.15) inset;
  border-radius: 26px;
  display: flex;
  justify-content: center;
  position: relative;
  .con {
    width: 680px;
    height: 124px;
    color: #1f2329;
    font-size: 22px;
    font-weight: 400;
    line-height: 140%;
    align-self: center;
  }
  img {
    width: 82px;
    position: absolute;
  }
  .quote-left {
    top: 70px;
    left: 72px;
  }
  .quote-right {
    bottom: 70px;
    right: 72px;
  }
  ${Media.homeLaptop`
    .con {
      width: 575px;
      height: 100px;
      font-size: 18px;
    }
    img {
      width: 69px;
      position: absolute;
    }
    .quote-left {
      top: 44px;
      left: 64px;
    }
    .quote-right {
      bottom: 44px;
      right: 64px;
    }
  `}
  ${Media.laptop`
    .con {
      width: 497px;
    }
    .quote-left {
      top: 43px;
      left: 55px;
    }
    .quote-right {
      bottom: 43px;
      right: 55px;
    }
  `}
  ${Media.ipad`
    width: 596px;
    .con {
      width: 398px;
      height: 150px;
      font-size: 18px;
    }
    img {
      width: 56px;
    }
    .quote-left {
      top: 46px;
      left: 42px;
    }
    .quote-right {
      bottom: 46px;
      right: 42px;
    }
  `}

  ${Media.phone`
    padding: 1.4rem 0 0.96rem;
    border-radius: 0.32rem;
    width: 100%;
    .con {
      width: 5.1rem;
      height: initial;
      font-size: 0.3rem;
    }
    img {
      width: 0.68rem;
    }
    .quote-left {
      top: 0.64rem;
      left: 0.48rem;
    }
    .quote-right {
      bottom: 0.64rem;
      right: 0.48rem;
    }
  `}
`
