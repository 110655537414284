import React, { useEffect, useState, useRef } from 'react'
import video1 from '../../../../images/home/video/video-1.mp4'
import video2 from '../../../../images/home/video/video-2.mp4'
import video3 from '../../../../images/home/video/video-3.mp4'
import Pause from '@images/home/pause.svg'
import Play from '@images/home/play.svg'
import {
  VideoCarouselWrap,
  Title,
  Controller,
  BtnList,
  VideoWrap,
  VideoList,
  VideoItem,
} from './atoms'

const throttleScroll = (
  fn: (currentIndex: number, isControl: boolean) => void,
  currentIndex: number,
  isControl: boolean
) => {
  let lastTime = 0
  return () => {
    const nowTime = Date.now()
    if (nowTime - lastTime < 200) return
    fn(currentIndex, isControl)
    lastTime = nowTime
  }
}

const videoList = [
  {
    video: video1,
    name: (
      <>
        Latency as Low as <span>10ms</span>
      </>
    ),
    text: 'Ultra-Fast and Highly Responsive',
  },
  {
    video: video2,
    name: (
      <>
        Frame Rate of Up to <span>144FPS</span>
      </>
    ),
    text: 'Just Like Working or Gaming Locally',
  },
  {
    video: video3,
    name: (
      <>
        Top-Tier <span>4:4:4 Color</span> Performance
      </>
    ),
    text: 'No Compression. No Color Loss.',
  },
]

export default function VideoCarousel() {
  const videoRefs = useRef(
    videoList.map(() => React.createRef<HTMLVideoElement>())
  ).current
  const containerRef = useRef<HTMLDivElement>(null) // 视频列表容器
  const wheelDistan = useRef(0) // 滚动位移
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [progress, setProgress] = useState<number>(0)
  const [isPlay, setIsPlay] = useState<boolean>(false)
  const [isControl, setIsControl] = useState<boolean>(false)
  const touchStartX = useRef(0) // 移动端触摸起点，用来判断向左还是向右滑动
  const [triggerWheel, setTriggerWheel] = useState(false) // 滚轮(触控板)滚动是否正在触发标识

  let wheelTimeout: NodeJS.Timeout | null = null

  const getCurrentVideo = (index: number) =>
    videoRefs[index]?.current as HTMLVideoElement

  const switchPlay = (index: number) => {
    const currentVideo = getCurrentVideo(index)
    currentVideo.currentTime = 0 // 重置进度
    // 轮播切换动效结束后播放
    const timer = setTimeout(() => {
      currentVideo.play()
      clearTimeout(timer)
    }, 1000)
  }

  const handelSwitch = (index: number, needPlay?: boolean) => {
    getCurrentVideo(currentIndex)?.pause() // 暂停上一个
    setProgress(0)
    setCurrentIndex(index)
    if (isPlay || needPlay) {
      switchPlay(index)
    }
  }

  const onEnded = (endedIndex: number) => {
    const nextIndex = endedIndex + 1
    if (nextIndex < videoList.length) {
      handelSwitch(nextIndex, true)
    }
  }

  const handelControl = () => {
    const currentVideo = getCurrentVideo(currentIndex)
    setIsControl(isPlay)
    if (isPlay) {
      currentVideo.pause()
    } else {
      currentVideo.play()
    }
  }

  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX.current = e.touches[0] && e.touches[0].clientX
  }

  const handleTouchEnd = (e: React.TouchEvent) => {
    const currentX = e.changedTouches[0].pageX
    const delaX = touchStartX.current - currentX
    if (delaX > 0 && currentIndex < videoList.length - 1) {
      handelSwitch(currentIndex + 1, true) // 向左滑动
    } else if (delaX < 0 && currentIndex > 0) {
      handelSwitch(currentIndex - 1, true) // 向右滑动
    }
  }

  useEffect(() => {
    // 滚轮(触控板)滚动结束时触发
    const handleWheelEnd = () => {
      wheelDistan.current = 0
      setTriggerWheel(false)
    }

    // 滚轮(触控板)滚动时触发
    const handleWheel = (event: WheelEvent) => {
      const { deltaX, deltaY } = event
      if (Math.abs(deltaX) > Math.abs(deltaY)) {
        // 水平滚动位移大时，阻止默认的垂直滚动行为
        event.preventDefault()
      }
      wheelDistan.current += event.deltaX
      // 位移量大于20时，切换视频
      if (Math.abs(wheelDistan.current) > 20 && !triggerWheel) {
        setTriggerWheel(true)
      }
      clearTimeout(wheelTimeout as NodeJS.Timeout) // 防抖
      wheelTimeout = setTimeout(handleWheelEnd, 40)
    }

    // 移动端手指切换视频时（水平位移大于垂直位移），禁止上下滚动
    const handleTouchMove = (e: TouchEvent) => {
      const touch = e.touches[0]
      const diffX = touch.clientX - touchStartX.current
      if (Math.abs(diffX) > 20) {
        e.preventDefault()
      }
    }

    const container = containerRef.current
    container?.addEventListener('wheel', handleWheel)
    container?.addEventListener('touchmove', handleTouchMove, {
      passive: false,
    })

    return () => {
      container?.removeEventListener('wheel', handleWheel)
      container?.removeEventListener('touchmove', handleTouchMove)
      clearTimeout(wheelTimeout as NodeJS.Timeout)
    }
  }, [])

  // triggerWheel从 false 变成 true 时触发
  useEffect(() => {
    if (!triggerWheel) return
    if (wheelDistan.current > 0 && currentIndex < videoList.length - 1) {
      // 向右滑动
      handelSwitch(currentIndex + 1, true)
    } else if (wheelDistan.current < 0 && currentIndex > 0) {
      // 向左滑动
      handelSwitch(currentIndex - 1, true)
    }
  }, [triggerWheel])

  useEffect(() => {
    const videoDom = document.querySelector('#video')
    const handleScroll = (index: number, control: boolean) => {
      const videoHeight = videoDom?.clientHeight || 0
      const videoToTop = videoDom?.getBoundingClientRect().top || 0
      const winHeight = window.innerHeight
      // 当视频上部露出1/2至下部露出2/3的位置播放视频
      const needPlay =
        winHeight - videoToTop >= videoHeight / 2 &&
        -videoToTop <= videoHeight / 3
      const isPaused = getCurrentVideo(index).paused // 视频播放状态

      // 滚动到顶部重置滚动播放条件
      if (window.scrollY === 0) {
        setIsControl(false)
      }
      // 如果是手动暂停，滚动后不需要播放视频
      if (control) return

      if (needPlay && isPaused) {
        getCurrentVideo(index).play()
      } else if (!needPlay && !isPaused) {
        getCurrentVideo(index).pause()
      }
    }

    handleScroll(currentIndex, false)

    const fn = throttleScroll(handleScroll, currentIndex, isControl)

    window.addEventListener('scroll', fn)

    return () => {
      window.removeEventListener('scroll', fn)
    }
  }, [currentIndex, isControl])

  return (
    <VideoCarouselWrap>
      <Title>
        <h2>Unleash Your Potentiality & Creativity</h2>
        <div
          className="text web-show"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-offset="60"
        >
          Get the highlights! Avica enables seamless remote access and
          high-quality images.
        </div>
      </Title>
      <Controller
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-offset="0"
        data-aos-delay="400"
      >
        <BtnList widthPer={progress}>
          {videoList.map((item, index) => (
            <div
              key={index}
              className={`${index === currentIndex ? 'progress' : ''} dot-btn`}
              onClick={() => {
                if (index === currentIndex) return
                handelSwitch(index)
              }}
            />
          ))}
        </BtnList>
        <div className="control-icon">
          <img src={isPlay ? Play : Pause} alt="" onClick={handelControl} />
        </div>
      </Controller>
      <VideoWrap id="video">
        <VideoList current={currentIndex} ref={containerRef}>
          {videoList.map((item, index) => (
            <VideoItem
              key={index}
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-offset="60"
              data-aos-delay={index * 200}
              onClick={() => {
                if (index !== currentIndex) {
                  handelSwitch(index, true)
                } else {
                  handelControl()
                }
              }}
              onTouchStart={(e: React.TouchEvent) => handleTouchStart(e)}
              onTouchEnd={(e: React.TouchEvent) => handleTouchEnd(e)}
            >
              <video
                key={index}
                ref={videoRefs[index]}
                muted
                playsInline
                onTimeUpdate={e => {
                  if (currentIndex !== index) return
                  const target = e.target as HTMLVideoElement
                  setProgress((target.currentTime / target.duration) * 100)
                }}
                onEnded={() => onEnded(index)}
                onPlay={() => setIsPlay(true)}
                onPause={() => setIsPlay(false)}
              >
                <source src={item.video} />
              </video>
              <div className="text-wrap">
                <div className="name">{item.name}</div>
                <div className="text">{item.text}</div>
              </div>
            </VideoItem>
          ))}
        </VideoList>
      </VideoWrap>
    </VideoCarouselWrap>
  )
}
