import styled from 'styled-components'
import { Media, color } from '@raylink-overseas/common/theme'
import { BigWrapper } from '@raylink-overseas/common/components'

export const CertificateWrap = styled(BigWrapper)`
  margin-bottom: 112px;
  display: flex;
  align-items: center;
  gap: 67px;
  ${Media.homeLaptop`
    width: 1184px;
    margin-bottom: 88px;
    gap: 24px;
  `}
  ${Media.homeLaptop`
    width: 1088px;
    margin-bottom: 64px;
    gap: 0;
  `}
  ${Media.ipad`
    width: 896px;
    flex-direction: column;
    margin-bottom: 58px;
    gap: 32px;
  `}

  ${Media.phone`
    width: 100%;
    margin-bottom: 0.76rem;
    flex-direction: column;
    gap: 0.48rem;
  `}
`

export const Title = styled.div`
  width: 644px;
  flex-shrink: 0;
  h2 {
    color: #1f2329;
    font-size: 40px;
    font-weight: 700;
  }
  .text {
    margin-top: 8px;
    color: ${color.textDark};
    font-size: 18px;
    font-weight: 400;
  }
  ${Media.homeLaptop`
    width: 470px;
    h2 {
      font-size: 34px;
    }
  `}
  ${Media.laptop`
    h2 {
      font-size: 32px;
    }
  `}
  ${Media.ipad`
    width: 896px;
    text-align: center;
    h2 {
      font-size: 30px;
    }
  `}

  ${Media.phone`
    width: 6.86rem;
    h2 {
      text-align: center;
      font-size: 0.48rem;
    }
    .text {
      margin-top: 0.16rem;
      text-align: center;
      font-size: 0.28rem;
    }
  `}
`

export const CertificateList = styled.div`
  width: 689px;
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;

  ${Media.homeLaptop`
    gap: 16px;
  `}
  ${Media.laptop`
    width: 619px;
  `}
  ${Media.ipad`
    width: 896px;
    gap: 12px;
  `}
  ${Media.phone`
    padding: 0 0.25rem;
    width: 100%;
    gap: 0.2rem;
  `}
`

export const CertificateItem = styled.div`
  width: 140px;
  height: 110px;
  flex-shrink: 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  .img-wrap {
    height: 64px;
    display: flex;
    align-items: center;
  }
  .text {
    height: 36px;
    color: ${color.textDark};
    text-align: center;
    font-size: 16px;
    font-weight: 400;
  }
  ${Media.laptop`
    height: 102px;
    .img-wrap {
      height: 56px;
      img {
        height: 56px;
      }
    }
  `}
  ${Media.ipad`
    width: auto;
    height: 52px;
    .text {
      display: none;
    }
    .img-wrap {
      height: 52px;
      img {
        height: 52px;
      }
    }
    
  `}

  ${Media.phone`
    width: 1.6rem;
    height: auto;
    .img-wrap {
      height: initial;
      img {
        width: 1.6rem;
        height: auto;
      }
    }
  `}
`
