import styled from 'styled-components'
import { Media, color } from '@raylink-overseas/common/theme'

export const VideoCarouselWrap = styled.div`
  margin: 112px 0;
  min-width: 1400px;
  text-align: center;
  ${Media.homeLaptop`
    min-width: 1184px;
    margin: 88px 0;
  `}
  ${Media.homeLaptop`
    min-width: 1088px;
    margin: 64px 0;
  `}
  ${Media.ipad`
    margin: 56px 0;
    min-width: 896px;
  `}
  ${Media.phone`
    margin: 0.76rem 0;
    padding: 0 0.2rem;
    min-width: 100%;
  `}
`

export const Title = styled.div`
  h2 {
    color: ${color.textBlack};
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  .text {
    color: ${color.textDark};
    font-size: 18px;
    font-weight: 400;
  }
  ${Media.homeLaptop`
    h2 {
      font-size: 34px;
    }
  `}
  ${Media.laptop`
    h2 {
      font-size: 32px;
    }
  `}
  ${Media.ipad`
    h2 {
      font-size: 30px;
    }
  `}
  ${Media.phone`
    h2 {
      font-size: 0.48rem;
      margin-bottom: 0.12rem;
    }
    .text {
      font-size: 0.28rem;
      display: block;
      margin: 0 0.32rem;
    }
  `}
`

export const Controller = styled.div`
  margin: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  .control-icon {
    width: 48px;
    height: 48px;
    border-radius: 60px;
    background: #fff;
    box-shadow: 0px 0px 20px 0px #eff5ff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  ${Media.homeLaptop`
    margin: 32px 0;
  `}
  ${Media.laptop`
    .control-icon {
      width: 44px;
      height: 44px;
    }
  `}
  ${Media.ipad`
    margin: 24px 0;
  `}

  ${Media.phone`
    margin: 0.48rem 0;
    gap: 0.2rem;
    .control-icon {
      width: 0.8rem;
      height: 0.8rem;
    }
  `}
`

export const BtnList = styled.div<{ widthPer: number }>`
  padding: 18px 20px;
  border-radius: 60px;
  background: #fff;
  box-shadow: 0px 0px 20px 0px #eff5ff;
  display: flex;
  gap: 16px;
  .dot-btn {
    width: 12px;
    height: 12px;
    border-radius: 60px;
    background: rgba(68, 95, 255, 0.1);
    transition: width cubic-bezier(0.33, 0, 0.67, 1) 400ms;
    cursor: pointer;
  }
  .progress {
    width: 80px;
    box-shadow: 2px 4px 10px 0px rgba(255, 255, 255, 0.2) inset,
      0px -3px 10px 0px rgba(68, 95, 255, 0.04) inset;
    cursor: initial;
    ::after {
      content: '';
      display: block;
      width: ${({ widthPer }) => `${widthPer}%`};
      height: 100%;
      border-radius: 60px;
      background: linear-gradient(
        98deg,
        #56bcee -6.92%,
        #445fff 39.9%,
        #3764f6 88.06%
      );
      box-shadow: 2px 4px 10px 0px rgba(255, 255, 255, 0.2) inset,
        0px -3px 10px 0px rgba(68, 95, 255, 0.04) inset;
    }
  }
  ${Media.laptop`
    padding: 16px 20px;
  `}

  ${Media.phone`
    padding: 0.3rem 0.34rem;
    gap: 0.26rem;
    .dot-btn {
      width: 0.2rem;
      height: 0.2rem;
      border-radius: 1.2rem;
    }
    .progress {
      width: 1.334rem;
      box-shadow: 2px 4px 10px 0px rgba(255, 255, 255, 0.20) inset, 0px -3px 10px 0px rgba(68, 95, 255, 0.04) inset;
      ::after {
        border-radius: 1.2rem;
      }
    }
  `}
`

export const VideoWrap = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  video {
    width: 100%;
    border-radius: 24px;
  }

  ${Media.phone`
    video {
      border: 1px solid #F6F6F6;
      border-radius: 0.12rem;
    }
  `}
`

export const VideoList = styled.div<{ current: number }>`
  width: 1080px;
  display: flex;
  gap: 56px;
  transform: translateX(${({ current }) => -current * 1136}px);
  transition: transform 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  ${Media.homeLaptop<{ current: number }>`
    width: 960px;
    gap: 48px;
    transform: translateX(${({ current }) => -current * 1008}px);
  `}
  ${Media.laptop<{ current: number }>`
    width: 880px;
    gap: 40px;
    transform: translateX(${({ current }) => -current * 920}px);
  `}
  ${Media.ipad<{ current: number }>`
    width: 760px;
    transform: translateX(${({ current }) => -current * 796}px);
    gap: 36px;
  `}

  ${Media.phone<{ current: number }>`
    width: 100%;
    min-width: initial;
    gap: 0.2rem;
    transform: translateX(${({ current }) => -current * 7.3}rem);
    transition: transform 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  `}
`

export const VideoItem = styled.div`
  width: 100%;
  flex-shrink: 0;
  border: 2px solid #f6f6f6;
  border-radius: 24px;
  position: relative;
  cursor: pointer;
  .text-wrap {
    display: flex;
    flex-direction: column;
    padding: 16px;
    color: #fff;
    gap: 4px 0px;
    flex-wrap: wrap;
    border-radius: 4px;
    background: rgba(58, 58, 58, 0.3);
    backdrop-filter: blur(5px);
    position: absolute;
    top: 56px;
    left: 16px;
    .name {
      font-size: 20px;
      text-align: left;
      span {
        font-weight: 700;
      }
    }
    .text {
      font-size: 24px;
      font-weight: 800;
    }
  }
  ${Media.homeLaptop`
    .text-wrap {
      top: 48px;
      left: 14px;
      .name {
        font-size: 18px;
      }
      .text {
        font-size: 22px;
      }
    }
  `}
  ${Media.laptop`
    .text-wrap {
      top: 44px;
      left: 12px;
      .name {
        font-size: 14px;
      }
      .text {
        font-size: 18px;
      }
    }
  `}
  ${Media.ipad`
    .text-wrap {
      top: 38px;
      left: 11px;
    }
  `}

  ${Media.phone`
    border-radius: 0.12rem;
    padding-top: 1.28rem;
    border: none;
    .text-wrap {
      padding: 0.12rem;
      gap: 0;
      border-radius: 0.08rem;
      background: #fff;
      color: #1F2329;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;
      .name {
        font-size: 0.32rem;
        text-align: center;
        span {
          color: ${color.primary};
        }
      }
      .text {
        font-size: 0.32rem;
      }
    }
  `}
`
