import React from 'react'
import Simple from '../simple'
import SceneCarousel from '../sceneCarousel'
import { SceneWrappe } from './atoms'
import { PlayStatusType } from '../..'
import { WarpId } from '../../contants'

export default function UserSceneWrapper({
  playStatus,
}: {
  playStatus: PlayStatusType
}) {
  return (
    <SceneWrappe>
      <Simple isPlay={playStatus && playStatus[WarpId.simpleWarp]} />
      <SceneCarousel
        isPlay={playStatus && playStatus[WarpId.sceneCarouselWrap]}
      />
    </SceneWrappe>
  )
}
