import React, { useEffect, useState } from 'react'
import bg1 from '@images/home/reviews/bg1.png'
import bg2 from '@images/home/reviews/bg2.png'
import bg3 from '@images/home/reviews/bg3.png'
import bgMobil1 from '@images/home/reviews/bg-mobil1.png'
import bgMobil2 from '@images/home/reviews/bg-mobil2.png'
import bgMobil3 from '@images/home/reviews/bg-mobil3.png'
import bgIpad1 from '@images/home/reviews/bg-ipad1.png'
import bgIpad2 from '@images/home/reviews/bg-ipad2.png'
import bgIpad3 from '@images/home/reviews/bg-ipad3.png'
import bg12801 from '@images/home/reviews/bg-1280-1.png'
import bg12802 from '@images/home/reviews/bg-1280-2.png'
import bg12803 from '@images/home/reviews/bg-1280-3.png'
import user1 from '@images/home/reviews/user1.png'
import user2 from '@images/home/reviews/user2.png'
import user3 from '@images/home/reviews/user3.png'
import star from '@images/home/reviews/star.png'
import quoteLeft from '@images/home/reviews/quote-left.svg'
import quoteRight from '@images/home/reviews/quote-right.svg'
import {
  UserReviewsWrap,
  Title,
  ReviewCard,
  UserBox,
  ReviewConWrap,
  ReviewCon,
} from './atoms'
import { WarpId } from '../../contants'

const reviewsList = [
  {
    userImg: user1,
    username: 'Johnson',
    occupation: 'Teacher',
    bgImg: bg1,
    bgImgIpad: bgIpad1,
    bgImg1280: bg12801,
    bgImgMobil: bgMobil1,
    reviewCon:
      'As a school teacher specializing in animation and game design, whether remotely guiding students or working on my design projects, I find your product extremely impressive, particularly the Digitizer feature.',
  },
  {
    userImg: user2,
    username: 'Emma',
    occupation: 'Freelancer',
    bgImg: bg2,
    bgImgIpad: bgIpad2,
    bgImg1280: bg12802,
    bgImgMobil: bgMobil2,
    reviewCon:
      'Avica offers all the features i require, is extremely cost-efficient, and its pricing aligns with my expectations.',
  },
  {
    userImg: user3,
    username: 'Williams',
    occupation: 'Entrepreneur',
    bgImg: bg3,
    bgImgIpad: bgIpad3,
    bgImg1280: bg12803,
    bgImgMobil: bgMobil3,
    reviewCon:
      "Our team utilizes Avica for collaborative tasks. Avica has significantly enhanced our productivity. Moreover, Avica's support team has provided prompt and efficient assistance when required. We had a highly positive experience with this.",
  },
]

export default function userReviews({ isPlay }: { isPlay: boolean }) {
  const [current, setCurrent] = useState<number>(0)

  useEffect(() => {
    if (!isPlay) return undefined
    const timer = setInterval(() => {
      if (current < reviewsList.length - 1) {
        setCurrent(current + 1)
      } else {
        setCurrent(0)
      }
    }, 5000)

    return () => {
      clearInterval(timer)
    }
  }, [current, isPlay])

  return (
    <UserReviewsWrap id={WarpId.userReviews}>
      <Title data-aos="fade-up" data-aos-duration="1000" data-aos-offset="60">
        User Reviews for Avica Remote Desktop
      </Title>
      <div className="background" />
      <ReviewCard
        background={reviewsList[current].bgImg}
        ipadBackground={reviewsList[current].bgImgIpad}
        background1280={reviewsList[current].bgImg1280}
        mobilBackground={reviewsList[current].bgImgMobil}
      >
        <div className="user-box-outer">
          {reviewsList.map((item, index) => (
            <UserBox
              key={index}
              onClick={() => setCurrent(index)}
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-offset="60"
              data-aos-delay={index * 200}
            >
              <div className="user">
                <img src={item.userImg} alt="" loading="lazy" />
                <div className="user-info">
                  <div className="name">{item.username}</div>
                  <div className="occupation">{item.occupation}</div>
                </div>
              </div>
              <img className="star" src={star} alt="" loading="lazy" />
            </UserBox>
          ))}
        </div>
        <ReviewConWrap>
          <ReviewCon>
            <img className="quote-left" src={quoteLeft} alt="" loading="lazy" />
            <div
              className="con"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-offset="60"
              data-aos-delay="200"
            >
              {reviewsList[current].reviewCon}
            </div>
            <img
              className="quote-right"
              src={quoteRight}
              alt=""
              loading="lazy"
            />
          </ReviewCon>
        </ReviewConWrap>
      </ReviewCard>
    </UserReviewsWrap>
  )
}
