import styled from 'styled-components'
import { Media } from '@raylink-overseas/common/theme'
import { BigWrapper } from '@raylink-overseas/common/components'

export const AdvancedFeaturesWrap = styled(BigWrapper)`
  margin-top: 112px;
  ${Media.homeLaptop`
    width: 1184px;
    margin-top: 88px;
  `}
  ${Media.laptop`
    width: 1088px;
    margin-top: 64px;
  `}
  ${Media.ipad`
    width: 896px;
    margin-top: 56px;
  `}
  ${Media.phone`
    width: 100%;
    margin-top: 0.76rem;
    padding: 0 0.32rem;
  `}
`

export const Title = styled.h2`
  width: 100%;
  color: #1f2329;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 12px;

  ${Media.homeLaptop`
    margin-bottom: 12px;
    font-size: 34px;
  `}
  ${Media.laptop`
    margin-bottom: 8px;
    font-size: 32px;
  `}
  ${Media.ipad`
    margin-bottom: 6px;
    font-size: 30px;
  `}
  ${Media.phone`
    margin-bottom: 0.12rem;
    font-size: 0.48rem;
  `}
`

export const Subtitle = styled.div`
  color: #445fff;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 40px;
  .link {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 28.67px;
    .arrow {
      transition: transform 0.3s ease;
    }
    &:hover {
      .arrow {
        transform: translateX(8px);
      }
    }
  }

  ${Media.homeLaptop`
    margin-bottom: 32px;
    font-size: 18px;
  `}
  ${Media.laptop`
    margin-bottom: 32px;
    font-size: 18px;
  `}
  ${Media.ipad`
    margin-bottom: 30px;
    font-size: 18px;
  `}
  ${Media.phone`
    margin-bottom: 0.48rem;
    font-size: 0.28rem;
  `}
`

export const CardWarp = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 32px;
  ${Media.homeLaptop`
    gap: 24px;
  `}
  ${Media.laptop`
    gap: 24px;
  `}
  ${Media.ipad`
    gap: 20px;
  `}
  ${Media.phone`
    gap: 0.32rem;
  `}
`

export const CardItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: relative;
  border-radius: 24px;
  img {
    transition: transform 0.5s ease;
  }
  :hover {
    cursor: pointer;
    img {
      transform: scale(1.05);
    }
  }
  ${Media.homeLaptop`
    border-radius: 16px;
    height: 352px;
    img {
      height: 352px;
      display: block;
      border-radius: 16px;
      object-fit: cover;
      width: 100%;
    }
  `}
  ${Media.laptop`
    border-radius: 16px;
    height: 323x;
    img {
      border-radius: 16px;
      height: 323px;
      display: block;
      object-fit: cover;
      width: 100%;
    }
  `}
  ${Media.ipad`
    border-radius: 12px;
    height: 266px;
    img {
      border-radius: 12px;
      height: 266px;
      display: block;
      object-fit: cover;
      width: 100%;
    }
  `}
  ${Media.phone`
    border-radius: 0.24rem;
    height: 4.8rem;
    img {
      border-radius: 0.24rem;
      height: 4.8rem;
      display: block;
      object-fit: cover;
      width: 100%;
    }
  `}
`

export const CardCon = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 296px;
  height: 100%;
  padding: 60px 0 0 60px;
  gap: 8px;
  border-radius: 24px 24px 0px 0px;
  ${Media.homeLaptop`
    padding: 48px;
    width: 250px;
  `}
  ${Media.laptop`
    padding: 40px;
    width: 250px;
  `}
  ${Media.ipad`
    padding: 33px;
    width: 206px;
  `}
  ${Media.phone`
    padding: 0.5rem;
    width: 3.88rem;
  `}
`

export const CardTitle = styled.h3`
  font-size: 34px;
  font-weight: 700;
  width: 261px;

  ${Media.homeLaptop`
    font-size: 32px;
    width: 250px;
  `}
  ${Media.laptop`
    font-size: 30px;
    width: 250px;
  `}
  ${Media.ipad`
    font-size: 24px;
    width: 206px;
  `}
  ${Media.phone`
    font-size: 0.4rem;
    width: 3.88rem;
  `}
`

export const CardDesc = styled.p`
  font-size: 18px;
  width: 296px;
  color: #595959;
  margin-bottom: 24px;

  ${Media.homeLaptop`
    font-size: 16px;
    width: 250px;
    margin-bottom: 24px;
  `}
  ${Media.laptop`
    font-size: 16px;
    width: 250px;
    margin-bottom: 24px;
  `}
  ${Media.ipad`
    font-size: 14px;
    width: 206px;
    margin-bottom: 16px;
  `}
  ${Media.phone`
    font-size: 0.28rem;
    width: 3.88rem;
    margin-bottom: 0.24rem;
  `}
`

export const CardButton = styled.button`
  background-color: #ffffff;
  color: #445fff;
  border: none;
  height: 55px;
  width: 158px;
  border-radius: 6px;
  cursor: pointer;
  gap: 10px;
  font-size: 18px;

  ${Media.homeLaptop`
    font-size: 16px;
    padding: 14px 12px;
    height: 48px;
    width: 150px;
  `}
  ${Media.laptop`
    font-size: 16px;
    padding: 14px 12px;
    height: 48px;
    width: 150px;
  `}
  ${Media.ipad`
    font-size: 14px;
    height: 42px;
    width: 120px;
    padding: 12px;
  `}
  ${Media.phone`
    font-size: 0.28rem;
    height: 0.84rem;
    width: 2.22rem;
    padding: 0.24rem 0.32rem;
  `}
`
