import React from 'react'
import styled from 'styled-components'
import { Media } from '@raylink-overseas/common/theme'

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  React.HTMLProps<HTMLButtonElement>
const StyledButton = styled.button`
  font-weight: 500;
  font-size: 18px;
  height: 55px;
  width: 158px;
  position: relative;
  display: inline-block;
  padding: 16px;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 2px 4px 10px 0px rgba(255, 255, 255, 0.2) inset;
  cursor: pointer;
  color: #445fff;
  text-align: center;
  white-space: nowrap;
  line-height: normal;
  transition: all 0.3s ease;
  &:hover {
    background: rgba(68, 95, 255, 1);
    color: #ffffff;
  }
  ${Media.homeLaptop`
    font-size: 16px;
    padding: 14px 12px;
    height: 48px;
    width: 150px;
  `}
  ${Media.laptop`
    font-size: 16px;
    padding: 14px 12px;
    height: 48px;
    width: 150px;
  `}
  ${Media.ipad`
    font-size: 14px;
    height: 42px;
    width: 120px;
    padding: 12px;
  `}
  ${Media.phone`
    font-size: 0.28rem;
    height: 0.84rem;
    width: 2.22rem;
    padding: 0.24rem 0.32rem;
  `}
` as React.FC<ButtonProps>

export default function Button(props: ButtonProps) {
  const { style, type, children, ...rest } = props
  return (
    <StyledButton style={style} type={type} {...rest}>
      {children}
    </StyledButton>
  )
}
