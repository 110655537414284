import React from 'react'
import { StartNowWrap, StartNowContent } from './atoms'
import Logo from '@svg/logo.svg'
import ButtonList from '@raylink-overseas/common/components/buttonList'
import Path from '@raylink-overseas/common/constants/path'

export default function StartNow() {
  return (
    <StartNowWrap>
      <StartNowContent>
        <Logo
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-offset="60"
        />
        <div
          className="start"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-offset="60"
          data-aos-delay="200"
        >
          Start Your <span>Remote Connection</span> Now
        </div>
        <ButtonList
          className="btns"
          btnInfo={[
            { text: 'Free Download', href: Path.downloads },
            { text: 'Request Business Trial', href: Path.businessLogin },
          ]}
        />
        {/* <FreeDownload
            className="start-download"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-offset="60"
            data-aos-delay="400"
          />
          <Button
            className="pricing"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-offset="60"
            data-aos-delay="600"
            as="a"
            href={paramsHref('/pricing/')}
            target="_blank"
            rel="noreferrer nofollow"
          >
            See Pricing
          </Button> */}
      </StartNowContent>
    </StartNowWrap>
  )
}
