import React, { useEffect, useState } from 'react'
import { SimpleWrap, StepWrap, StepItem } from './atom'
import Step1Src from '@images/home/step1.png'
import Step2Src from '@images/home/step2.png'
import Step3Src from '@images/home/step3.png'
import { WarpId } from '../../contants'

const stepList = [
  {
    name: 'Step 1',
    feature: 'Install',
    text: `Install Avica app on the devices you want to remote connect.`,
    imgSrc: Step1Src,
  },
  {
    name: 'Step 2',
    feature: 'Login',
    text: `Register your Avica account and log in.`,
    imgSrc: Step2Src,
  },
  {
    name: 'Step 3',
    feature: 'Connect',
    text: `Enter remote device's Avica ID on your device and start a remote connection.`,
    imgSrc: Step3Src,
  },
]

export default function Simple({ isPlay }: { isPlay: boolean }) {
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    if (!isPlay) return undefined
    const interval = setInterval(() => {
      if (activeIndex === 2) {
        setActiveIndex(0)
      } else {
        setActiveIndex(activeIndex + 1)
      }
    }, 3000)

    return () => {
      clearInterval(interval)
    }
  }, [activeIndex, isPlay])

  return (
    <SimpleWrap id={WarpId.simpleWarp}>
      <div
        className="title"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-offset="60"
      >
        Simple is the Best
      </div>
      <div
        className="desc"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-offset="60"
        data-aos-delay="200"
      >
        Everyone can Avica! With only 3 steps to remote access to computers or
        mobile devices.
      </div>
      <StepWrap>
        {stepList.map((item, index) => (
          <StepItem key={index} isActive={index === activeIndex}>
            <div
              className={index === 1 ? 'cross-line' : undefined}
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-offset="60"
              data-aos-delay={index * 200}
            >
              <div className="btn">{item.name}</div>
            </div>
            <div
              className="feature-desc-div"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-offset="60"
              data-aos-delay={index * 200}
            >
              <div className="feature">{item.feature}</div>
              <div className="item-desc">{item.text}</div>
            </div>
            <div
              className="img-div"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-offset="60"
              data-aos-delay={index * 400}
            >
              <img src={item.imgSrc} alt="" loading="lazy" />
            </div>
          </StepItem>
        ))}
      </StepWrap>
    </SimpleWrap>
  )
}
