import React from 'react'
import Button from './button'
import remote from '@images/home/features-new/RemoteDesktopAccess.png'
import monitor from '@images/home/features-new/Multi-MonitorSupport.png'
import privacy from '@images/home/features-new/PrivacyMode.png'
import file from '@images/home/features-new/FileTransfer.png'
import Path from '@raylink-overseas/common/constants/path'
import RightArrow from '@images/home/right.svg'
import {
  AdvancedFeaturesWrap,
  Title,
  Subtitle,
  CardWarp,
  CardItem,
  CardDesc,
  CardTitle,
  CardCon,
} from './atoms'

const cardList = [
  {
    title: 'Remote Desktop Access',
    desc: 'Easily access and control your remote desktop from any location and any device.',
    imgSrc: remote,
    link: Path.remoteAccess,
  },
  {
    title: 'Multi-Monitor Support',
    desc: 'View and work with multiple monitors from a remote computer on the local device.',
    imgSrc: monitor,
    link: Path.multiMonitorSupport,
  },
  {
    title: 'Privacy Mode',
    desc: "Block others from seeing your activities by rendering the remote device's screen black.",
    imgSrc: privacy,
    link: Path.privacyMode,
  },
  {
    title: 'File Transfer',
    desc: 'Feature a patented protocol for high-speed transfer, 100 times faster than FTP/HTTP.',
    imgSrc: file,
    link: Path.fileTransfer,
  },
]

export default function AdvancedFeatures() {
  return (
    <AdvancedFeaturesWrap>
      <Title data-aos="fade-up" data-aos-duration="1000" data-aos-offset="60">
        Discover Highlighted Features
      </Title>
      <Subtitle
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-offset="60"
      >
        <a
          className="link"
          href={Path.allFeatures}
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-offset="60"
          rel="noreferrer nofollow"
        >
          Explore More Features <img className="arrow" src={RightArrow} />
        </a>
      </Subtitle>
      <CardWarp>
        {cardList.map((item, i) => (
          <CardItem
            key={item.title}
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-offset="60"
            data-aos-delay={i * 200}
          >
            <img src={item.imgSrc} alt={item.title} loading="lazy" />
            <CardCon>
              <CardTitle>{item.title}</CardTitle>
              <CardDesc>{item.desc}</CardDesc>
              <Button
                as="a"
                href={item.link}
                target="_blank"
                rel="noreferrer nofollow"
              >
                Learn More
              </Button>
            </CardCon>
          </CardItem>
        ))}
      </CardWarp>
    </AdvancedFeaturesWrap>
  )
}
