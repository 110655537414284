import styled from 'styled-components'
import { Media, color } from '@raylink-overseas/common/theme'

export const BlogsWrap = styled.div`
  margin: 0 auto;
  margin-bottom: 112px;
  width: 100%;
  min-width: 1400px;
  max-width: 1640px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 40px;
    font-weight: 700;
    color: #1f2329;
    line-height: 51px;
  }
  .desc {
    font-size: 18px;
    color: ${color.textDark};
    margin: 16px auto 56px;
  }
  .more {
    color: ${color.primary};
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    > img {
      transition: all 0.4s ease-in-out;
    }
    &:hover {
      > img {
        transform: translateX(8px);
      }
    }
  }
  ${Media.homeLaptop`
    min-width: 1184px;
    max-width: 1360px;
    margin-bottom: 88px;
    .title {
      font-size: 34px;
    }
    .desc {
      margin: 8px auto 48px;
    }
  `}
  ${Media.laptop`
    min-width: 1088px;
    max-width: 1280px;
    margin-bottom: 64px;
    .title {
      font-size: 32px;
    }
    .desc {
      margin: 8px auto 40px;
    }
  `}
  ${Media.ipad`
    min-width: 896px;
    max-width: 1164px;
    margin-bottom: 58px;
    .title {
      font-size: 30px;
    }
    .desc {
      margin: 8px auto 32px;
    }
  `}
  ${Media.phone`
    min-width: initial;
    margin-bottom: 0.76rem;
    .title {
      font-size: 0.48rem;
      line-height: 0.6rem;
    }
    .desc {
      font-size: 0.28rem;
      line-height: 0.36rem;
      margin: 0.16rem 0.32rem 0.48rem;
    }
    .more {
      font-size: 0.28rem;
      line-height: 0.36rem;
      > img {
        width: 0.36rem;
      }
    }
  `}
`

export const CarouselWrap = styled.div`
  width: 100%;
  max-width: 1640px;
  display: flex;
  justify-content: center;
  position: relative;
  .btn {
    width: 48px;
    height: 48px;
    border-radius: 4px;
    background: rgba(41, 41, 41, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    &.btn-pre {
      left: 0;
    }
    &.btn-next {
      right: 0;
    }
    &.disabled {
      background: rgba(41, 41, 41, 0.05);
    }
  }
  ${Media.homeLaptop`
    max-width: 1360px;
  `}
  ${Media.laptop`
    max-width: 1240px;
  `}
  ${Media.ipad`
    max-width: 984px;
    .btn {
      display: none;
    }
  `}

  ${Media.phone`
    width: auto;
    .btn {
      display: none;
    }
  `}
`

export const Carousel = styled.div`
  padding-bottom: 52px;
  width: 1480px;
  overflow: hidden;
  ${Media.homeLaptop`
    width: 1264px;
  `}
  ${Media.phone`
    width: 100%;
  `}
`

export const BlogList = styled.div<{ index: number; carouselWidth: number }>`
  display: flex;
  justify-content: left;
  text-align: left;
  transform: translateX(
    ${({ index, carouselWidth }) => -index * carouselWidth}px
  );
  transition: transform 300ms;
  .home-blog {
    padding-bottom: 24px;
    width: 100%;
    .cover {
      width: 397px;
      height: 298px;
      margin-bottom: 20px;
    }
    .info-wrap {
      padding: 0 8px;
    }
  }
  ${Media.homeLaptop`
    .home-blog {
      .cover {
        width: 330px;
        height: 248px;
        margin-bottom: 18px;
      }
      .title {
        width: 314px;
        height:  44px;
        margin: 14px 0 8px;
        font-size: 18px;
      }
    }
  `}
  ${Media.laptop`
    .home-blog {
      padding: 16px;
      .cover {
        width: 24.53vw;
        height: 18.43vw;
        margin-bottom: 16px;
      }
      .title {
        width: 23.28vw;
        margin: 10px 0 8px;
      }
    }
  `}
  ${Media.ipad`
    .home-blog {
      width: 288px;
      .cover {
        width: 256px;
        height: 192px;
      }
      .title {
        width: 248px;
      }
    }
  `}

  ${Media.phone`
    width: 100%;
    flex-direction: column;
    margin-bottom: 0.64rem;
    .home-blog {
      padding-bottom: 0;
      width: 100%;
      .cover {
        width: 6.46rem;
        height: 5rem;
      }
      .info-wrap {
        padding: 0 0.16rem;
      }
      .title {
        width: 6.2rem;
      }
    }
  `}
`

export const CarouselItem = styled.div`
  width: 100%;
  max-width: 1480px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  gap: 1.7vw;
  padding: 0 40px;
  ${Media.homeLaptop`
    max-width: 1264px;
    gap: 1.5vw;
  `}
  ${Media.laptop`
    max-width: 1264px;
    gap: 1.2vw;
    padding: 0 60px;
  `}
  ${Media.ipad`
    padding: 0 2.44vw;
  `}
  ${Media.phone`
    width: 100%;
    flex-direction: column;
    gap: 0.48rem;
    padding: 0;
    max-width: 100%;
  `}
`
