import styled from 'styled-components'
import { BigWrapper } from '@raylink-overseas/common/components'
import { Media, color } from '@raylink-overseas/common/theme'

export const SimpleWrap = styled(BigWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    color: #1f2329;
    font-size: 40px;
    font-weight: 700;
  }
  .desc {
    color: ${color.textDark};
    font-size: 18px;
    margin: 8px 0 32px;
  }
  ${Media.homeLaptop`
    width: 1184px;
    .title {
      font-size: 34px;
    }
  `}
  ${Media.laptop`
    width: 1088px;
    .title {
      font-size: 32px;
    }
  `}
  ${Media.ipad`
    width: 896px;
    .title {
      font-size: 30px;
    }
  `}
  ${Media.phone`
    width: 100%;
    .title {
      font-size: 0.48rem;
    }
    .desc {
      width: 6.88rem;
      text-align: center;
      color: ${color.textDark};
      font-size: 0.28rem;
      margin: 0.16rem 0 0.48rem;
    }
  `}
`

export const StepWrap = styled(BigWrapper)`
  display: flex;
  justify-content: space-between;
  min-width: 1400px;
  ${Media.homeLaptop`
    width: 1184px;
    min-width: 1184px;
  `}
  ${Media.laptop`
    width: 1088px;
    min-width: 1088px;
  `}
  ${Media.ipad`
    width: 896px;
    min-width: 896px;
  `}
  ${Media.phone`
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-width: auto;
    height: 5.46rem;
    width: 6.42rem;
  `}
`

export const StepItem = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  .btn {
    width: 112px;
    text-align: center;
    border-radius: 60px;
    font-weight: 500;
    transition: all 0.4s ease-in;
    color: ${({ isActive }) => (isActive ? '#fff' : color.primary)};
    padding: 10px 0;
    background: ${({ isActive }) =>
      isActive
        ? `linear-gradient(290deg, #445fff 53.77%, #55bcee 102.07%)`
        : `rgba(68, 95, 255, 0.1)`};
    box-shadow: 2px 4px 10px 0px rgba(255, 255, 255, 0.2) inset,
      0px -3px 10px 0px rgba(68, 95, 255, 0.04) inset;
    text-wrap: nowrap;
  }
  .cross-line {
    position: relative;
    ::before,
    ::after {
      content: '';
      position: absolute;
      top: 50%;
      width: 367px;
      height: 1.5px;
      border-bottom: 1.5px dashed rgba(68, 95, 255, 0.15);
    }
    &::before {
      left: -367px;
    }
    &::after {
      left: 112px;
    }
  }
  .feature {
    font-size: 24px;
    text-align: center;
    transition: all 0.4s ease-in;
    color: ${({ isActive }) => (isActive ? color.primary : '#1f2329')};
    font-weight: 700;
    margin: 32px 0 16px;
  }
  .item-desc {
    color: ${color.textDark};
    width: 354px;
    height: 40px;
    margin-bottom: 32px;
    text-align: center;
  }
  .img-div {
    width: 445px;
    height: 350px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ isActive }) => (isActive ? '#fff' : 'transparent')};
    img {
      width: 425px;
      height: 330px;
    }
  }
  ${Media.homeLaptop`
    .feature {
      font-size: 22px;
      margin: 24px 0 12px;
    } 
    .cross-line {
      ::before,
      ::after {
        width: 293px;
      }
      &::before {
        left: -293px;
      }
      &::after {
        left: 112px;
      }
    }
    .img-div {
      width: 373px;
      height: 295px;
      border-radius: 24px;
      img {
        width: 353px;
        height: 275px;
      }
    }
  `}
  ${Media.laptop`
    .item-desc {
      width: 324px;
      margin-bottom: 24px;
    }
    .feature {
      font-size: 20px;
      margin: 16px 0 12px;
    } 
    .cross-line {
      ::before,
      ::after {
        width: 260px;
      }
      &::before {
        left: -260px;
      }
      &::after {
        left: 112px;
      }
    }
    .img-div {
      width: 345px;
      height: 273px;
      img {
        width: 325px;
        height: 253px;
      }
    }
  `}

  ${Media.ipad`
    .btn {
      width: 88px;
      padding: 8px 0;
    }
    .item-desc {
      width: 240px;
      height: 60px;
    }
    .feature {
      margin: 16px 0 8px;
    }
    .cross-line {
      ::before,
      ::after {
        width: 222px;
      }
      &::before {
        left: -222px;
      }
      &::after {
        left: 88px;
      }
    }
    .img-div {
      width: 277px;
      height: 220px;
      border-radius: 16px;
      img {
        width: 257px;
        height: 200px;
      }
    }
  `}
  ${Media.phone<{ isActive: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-size: 0.28rem;
    justify-content: space-between;
    .btn {
      width: 1.36rem;
      height: 0.68rem;
      border-radius: 1.2rem;
      padding: 0.16rem 0;
    }
    .cross-line {
      ::before,
      ::after {
        content: '';
        position: absolute;
        left: 50%;
        width: 1px;
        height: 1.2rem;
        border-bottom: none;
        border-left: 1px dashed rgba(68, 95, 255, 0.15);
      }
      &::before {
        left: 50%;
        top: -1.2rem;
      }
      &::after {
        top: 0.68rem;
      }
    }
    .feature {
      font-size: 0.36rem;
      text-align: left;
      margin: 0 0 0.16rem;
    }
    .item-desc {
      color: ${color.textDark};
      width: 4.74rem;
      height: 1.08rem;
      margin-bottom: 0.18rem;
      text-align: left;
    }
    .img-div {
      display: none;
    }
  `}
`
