import React from 'react'
import certificateISO27001 from '../../../../images/home/certificate/ISO27001.png'
import certificateHIPAA from '../../../../images/home/certificate/HIPAA.png'
import certificateTPN from '../../../../images/home/certificate/TPN.png'
import certificateIabm from '../../../../images/home/certificate/iabm-member.png'
import certificateDPP from '../../../../images/home/certificate/DPP.png'
import certificateDPPproduction from '../../../../images/home/certificate/DPP-production.png'
import certificateDPPbroadcast from '../../../../images/home/certificate/DPP-broadcast.png'
import {
  CertificateWrap,
  Title,
  CertificateList,
  CertificateItem,
} from './atoms'

const certificateList = [
  {
    img: certificateISO27001,
    text: 'ISO27001 Certification',
  },
  {
    img: certificateHIPAA,
    text: 'HIPAA Verified',
  },
  {
    img: certificateTPN,
    text: 'TPN Verified',
  },
  {
    img: certificateIabm,
    text: 'Member of iabm',
  },
  {
    img: certificateDPP,
    text: 'Member of DPP',
  },
  {
    img: certificateDPPproduction,
    text: 'DPP Production Certified',
  },
  {
    img: certificateDPPbroadcast,
    text: 'DPP Broadcast Certified',
  },
]

export default function SecurityCertificate() {
  return (
    <CertificateWrap>
      <Title>
        <h2 data-aos="fade-up" data-aos-duration="1000" data-aos-offset="60">
          Industry-Acknowledged Security
        </h2>
        <div
          className="text"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-offset="60"
          data-aos-delay="200"
        >
          Avica provides end to end comprehensive AES-256 encryption and a
          privacy mode to prevent privacy breaches.
        </div>
      </Title>
      <CertificateList>
        {certificateList.map((item, index) => (
          <CertificateItem
            key={index}
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-offset="60"
            data-aos-delay={index * 200}
          >
            <div className="img-wrap">
              <img src={item.img} alt="" />
            </div>
            <div className="text">{item.text}</div>
          </CertificateItem>
        ))}
      </CertificateList>
    </CertificateWrap>
  )
}
