/**
 * 分类标签组件
 * @param cate 标签内容，字符串格式
 */

import React from 'react'
import styled from 'styled-components'
import { Media, color } from '../theme'

const CategoryWrap = styled.div`
  width: fit-content;
  height: 31px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: #f5f7ff;
  font-size: 16px;
  font-weight: 500;
  color: ${color.primary};
  ${Media.ipad`
    font-size: 14px;
  `}
  ${Media.phone`
    height: 0.58rem;
    padding: 0.16rem 0.24rem;
    border-radius: 0.08rem;
    font-size: 0.24rem;
  `}
`

const Category = ({ cate }: { cate: string }) => {
  return <CategoryWrap>{cate}</CategoryWrap>
}

export default Category
