import styled from 'styled-components'
import Media from '../../theme/media'
import { color } from '../../theme/variables'
import { MultipleLineEllipsis } from '@raylink-overseas/common/components'

export const BlogItemWrap = styled.div`
  width: 384px;
  padding: 24px 24px 27px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 20px 40px 2px rgba(204, 204, 204, 0.2);
  }
  .cover {
    margin-bottom: 24px;
    width: 336px;
    height: 252px;
    border-radius: 16px;
    background: #d7e0ec;
  }
  .info-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      margin: 16px 0;
      width: 100%;
      height: 48px;
      ${MultipleLineEllipsis(2)}
      color: ${color.textBlack};
      font-size: 20px;
      font-weight: 800;
      line-height: 120%;
      text-align: left;
    }
  }
  .bottom {
    margin-top: 4px;
    color: ${color.textDark};
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    .avatar {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
    .info-text {
      flex: 1;
      align-self: center;
    }
    .username {
      margin-bottom: 6px;
      font-weight: 700;
    }
    .time {
      flex: 1;
      display: flex;
      justify-content: space-between;
    }
    .right {
      align-self: flex-end;
    }
  }
  ${Media.laptop`
    width: 352px;
    padding: 22px 22px 27px;
    .cover {
      margin-bottom: 20px;
      width: 308px;
      height: 231px;
    }
    .info-wrap {
      .title {
        margin: 11px 0 16px;
      }
    }
  `}
  ${Media.ipad`
    width: 288px;
    padding: 18px 18px 22px;
    .cover {
      margin-bottom: 12px;
      width: 252px;
      height: 189px;
    }
    .info-wrap {
      .title {
        height: 22px;
        margin: 8px 0 16px;
        ${MultipleLineEllipsis(1)}
      }
    }
    .bottom {
      font-size: 14px;
    }
  `}
  ${Media.phone`
    width: 6.46rem;
    padding: 0;
    &:hover {
      box-shadow: none;
    }
    .cover {
      margin-bottom: 0.28rem;
      width: 6.46rem;
      height: 4.84rem;
      border-radius: 0.32rem;
    }
    .info-wrap {
      padding: 0 0.28rem;
      .title {
        margin: 0.2rem 0 0;
        width: 5.9rem;
        height: auto;
        max-height: 0.76rem;
        font-size: 0.32rem;
      }
    }
    .bottom {
      margin: 0.24rem 0 0.12rem;
      font-size: 0.24rem;
      .left {
        gap: 0.16rem;
      }
      .avatar {
        width: 0.64rem;
        height: 0.64rem;
      }
      .username {
        margin-bottom: 0.04rem;
      }
    }
  `}
`
