import React from 'react'
import Category from '../blogCategory'
import { dateFormatEn } from '../../utils/date'
import { BlogItemWrap } from './atoms'

interface Props {
  data: Blog
  hasBottom?: boolean
  className?: string
}

const BlogItem = ({ data, hasBottom = true, ...rest }: Props) => {
  const dateFormat = dateFormatEn(data?.publish_time)
  return (
    <BlogItemWrap {...rest}>
      <img className="cover" src={data?.cover?.url} alt="" loading="lazy" />
      <div className="info-wrap">
        <div className="article">
          {data?.categories && <Category cate={data?.categories[0]?.name} />}
          <h3 className="title">{data?.title}</h3>
        </div>
        {hasBottom && (
          <div className="bottom">
            <img
              className="avatar"
              src={data?.avatar?.url}
              alt=""
              loading="lazy"
            />
            <div className="info-text">
              <div className="username">{data?.author}</div>
              <div className="time">
                <div className="time-left">{dateFormat}</div>
                <div className="time-right">{data?.reading_time}min read</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </BlogItemWrap>
  )
}

export default BlogItem
